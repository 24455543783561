import React, { useEffect, useRef, useState } from "react";
import {
  CheckGoogleLogin,
  CheckUserExists,
  GenerateOTP,
  GetCountryList,
  GetLocationDetails,
  GetSpecializationTypes,
  GetTimezone,
  GetUserRole,
  RegisterCopilotUserJuggad,
  SSOAuthLogin,
  VerifyOTP,
} from "../api/_request";
import { toast } from "sonner";
import {
  cn,
  countriesJSON,
  formatTime,
  getDeviceId,
  getDeviceType,
  setCookieOnClientSide,
  setDataLayer,
  validatePhoneNumber,
} from "../lib/utils";

import { Loader2 } from "lucide-react";

import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

import EmailInput from "./inputs/emailInput";
import PhoneNumberInput from "./inputs/phoneNumberInput";
import UserNameInput from "./inputs/userNameInput";
import CountrySelector from "./selectors/countrySelector";
import TimzoneSelector from "./selectors/timzoneSelector";
import TncAgreeSwitch from "./switch/tncAgreeSwitch";
import SpecializationSelector from "./selectors/specializationSelector";
import OtpVerifierInput from "./inputs/otpVerifierInput";
import Drawer from "./ui/drawer";
import LoginButton from "./buttons/loginButton";
import AppleSignInButton from "react-apple-signin-auth";

const ExpertRegisterForm = () => {
  const searchBarParams = new URLSearchParams(window.location.search);
  const redirectURL = searchBarParams.get("redirect");
  const utmSource = searchBarParams.get("utm_source");
  const utmMedium = searchBarParams.get("utm_medium");
  const utmCampaign = searchBarParams.get("utm_campaign");
  const utmID = searchBarParams.get("utm_id");

  ///States
  const [selected, setSelected] = useState<any>(countriesJSON[101].phone_code);
  const [formattedCountries, setFormattedCountries] = useState<any[]>([]);
  const [inputISO, setInputISO] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [errors, setErrors] = useState<FormErrors>({
    email: "",
    phoneNumber: "",
    fullName: "",
    otp: "",
  });
  const [formData, setFormData] = useState<FormDataInterface>({
    email: "",
    fullName: "",
    firstName: "",
    lastName: "",
    title: "`",
    referralCode: "",
    selectedCountry: "",
    selectedCountryCode: "",
    selectedCountryName: "",
    selectedTimezone: "",
    selectedRegion: null,
    selectedCity: null,
    selectedPostalCode: null,
    isTermsAccepted: false,
    socialID: null,
    socialType: null,
    phoneCode: "",
    mobileNo: "",
    userType: 4,
    countryID: "",
    userId: "",
    institutionId: "1",
    referenceId: "",
    groupId: "",
    device_type: "",
    device_id: "",
    specialization: "",
  });
  const [countries, setCountries] = useState<any>([]);
  const [timezoneList, setTimezoneList] = useState<any>(null);
  const [specializations, setSpecialization] = useState<any[] | null>(null);

  const [disableRegister, setDisableRegister] = useState<any>({
    step0: true,
    step1: true,
    step2: true,
  });
  const [counter, setCounter] = useState<number>(30);
  const [otp, setOtp] = useState<string>("");

  const [showAPILoader, setShowAPILoader] = useState<boolean>(false);
  const [verifyOTPCounter, setVerifyOTPCounter] = useState<number>(0);
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);

  const [shouldRegister, setShouldRegister] = useState<boolean>(false);
  const [isRegistering, setIsRegistering] = useState(false);

  const [isAppleSDKLoaded, setIsAppleSDKLoaded] = useState(false);
  const [userCountry, setUserCountry] = useState<string>("");
  ///Refs
  const countrySearchInputRef = useRef(null);
  const registeringRef = useRef(false);

  const validEmailRegex = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;

  ///UseEffect Region Start

  //Load Apple SDK
  useEffect(() => {
    const checkAppleSDK = () => {
      if (window.AppleID) {
        window.AppleID.auth.init({
          clientId: process.env.REACT_APP_APPLE_SERVICE_ID, // Replace with your Service ID
          scope: "name email",
          redirectURI: "https://accounts.unitedwecare.com", // Ensure this matches your Apple Developer configuration
          state: "optional-state",
          usePopup: true, // Use true to keep the user in the same page
        });
        setIsAppleSDKLoaded(true);
      } else {
        console.error("AppleID SDK not loaded");
      }
    };

    // Check if the Apple SDK is loaded
    checkAppleSDK();

    // Optionally, add an interval to keep checking until the SDK is loaded
    const interval = setInterval(() => {
      checkAppleSDK();
    }, 1000);

    // Clear the interval after 10 seconds
    setTimeout(() => {
      clearInterval(interval);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  //Register Via useEffect
  useEffect(() => {
    if (shouldRegister && formData?.email !== "" && !isRegistering) {
      //registeringRef.current = true;
      setIsRegistering(true);
      registerUser();
    }
  }, [shouldRegister, formData]);

  //Get Country list
  useEffect(() => {
    getCountryList();
    getSpecializations();
    getIPDetails();
  }, []);

  //Too many attempts Counter
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    if (verifyOTPCounter > 0) {
      timer = setTimeout(() => setVerifyOTPCounter(verifyOTPCounter - 1), 1000); // Decrease counter every second

      if (step == 2) {
        setErrors((prevData: any) => ({
          ...prevData,
          otp: `Incorrect OTP. Please wait ${formatTime(
            verifyOTPCounter
          )} minutes before attempting again.`,
        }));
      }
    } else if (verifyOTPCounter === 0) {
      //setResendAllowed(true); // Enable resend button when countdown reaches 0
      setErrors((prevData: any) => ({ ...prevData, otp: "" }));
    }

    return () => clearTimeout(timer);
  }, [verifyOTPCounter]);

  /*useEffect(() => {
    if (countrySearchInputRef.current) {
      const formattedData = countriesJSON.filter((item) =>
        item.name.toLowerCase().includes(inputISO.trim().toLowerCase())
      );

      setFormattedCountries(formattedData);
    }
  }, [inputISO, countrySearchInputRef]);*/

  //Fetch TimezoneList based on Country
  // useEffect(() => {
  //   if (formData?.selectedCountry !== "") {
  //     getTimezoneList();

  //     //Set Phonecode based on selected country
  //     const formattedData = countriesJSON.filter(
  //       (item) => item.iso2 === formData?.selectedCountry
  //     );

  //     setFormData((prevData: any) => ({
  //       ...prevData,
  //       phoneCode: formattedData[0].phone_code,
  //     }));
  //   } else {
  //     setTimezoneList(null);
  //   }
  // }, [formData?.selectedCountry]);

  //Handle Timezone for countries which have only one Timezone
  // useEffect(() => {
  //   if (timezoneList !== null && timezoneList.length == 1) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       selectedTimezone: timezoneList[0].name,
  //     }));
  //   }
  // }, [timezoneList]);

  //Button Disabled Logic
  useEffect(() => {
    if (
      formData?.email !== undefined &&
      formData?.email !== null &&
      formData?.email.trim() !== "" &&
      formData?.email?.toLocaleLowerCase().match(validEmailRegex) &&
      formData?.isTermsAccepted &&
      errors?.email.trim() == ""
    ) {
      setDisableRegister((prevData: any) => ({
        ...prevData,
        step0: false,
      }));
    } else {
      setDisableRegister((prevData: any) => ({
        ...prevData,
        step0: true,
      }));
    }

    if (
      formData.mobileNo !== undefined &&
      formData.mobileNo.trim() !== "" &&
      validatePhoneNumber(formData?.mobileNo, formData?.selectedCountry) &&
      formData.phoneCode !== "" &&
      formData.specialization !== "" &&
      errors?.phoneNumber == ""
    ) {
      setDisableRegister((prevData: any) => ({
        ...prevData,
        step1: false,
      }));
    } else {
      setDisableRegister((prevData: any) => ({
        ...prevData,
        step1: true,
      }));
    }

    if (otp.length == 4) {
      setDisableRegister((prevData: any) => ({
        ...prevData,
        step2: false,
      }));
    } else {
      setDisableRegister((prevData: any) => ({
        ...prevData,
        step2: true,
      }));
    }

    if (errors?.otp !== "") {
      if (otp.length > 0) {
        setErrors((prevData) => ({
          ...prevData,
          otp: "",
        }));
      }
    }
  }, [formData, errors, otp]);

  //Counter for Resend OTP
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (step === 2) {
      if (counter > 0) {
        timer = setTimeout(() => setCounter(counter - 1), 1000); // Decrease counter every second
      } else if (counter === 0) {
        //setResendAllowed(true); // Enable resend button when countdown reaches 0
      }
    }
    return () => clearTimeout(timer);
  }, [step, counter]);

  ///UseEffect Region End

  ///Functions Region Start
  const getIPDetails = async () => {
    try {
      const ipDetails = await GetLocationDetails();

      if (ipDetails?.status === 200) {
        const ipTimezone = ipDetails?.data?.data?.timezone;
        const ipCountry = ipDetails?.data?.data?.country;
        const ipRegion = ipDetails?.data?.data?.region;
        const ipCity = ipDetails?.data?.data?.city;
        const ipPostalCode = ipDetails?.data?.data?.postalCode;
        // console.log("ipDetails", ipDetails)

        //Set Phonecode based on selected country
        const formattedData = countriesJSON.filter(
          (item) => item.iso2 === ipCountry
        );
        const formattedCountry = formattedData[0]?.name;
        setUserCountry(ipCountry);
        //console.log("formattedData", formattedData)
        setFormData((prevData: any) => ({
          ...prevData,
          selectedCountry: formattedCountry,
          selectedCountryCode: ipCountry,
          selectedRegion: ipRegion,
          selectedCity: ipCity,
          selectedTimezone: ipTimezone,
          selectedPostalCode: ipPostalCode,
          phoneCode: formattedData[0].phone_code,
          mobileNo: `${formattedData[0].phone_code} 0000000000`,
        }));
      }
    } catch (error) {}
  };

  const resetErrors = () => {
    setErrors({
      email: "",
      phoneNumber: "",
      fullName: "",
      otp: "",
    });
  };

  const getSpecializations = async () => {
    try {
      const specializationList = await GetSpecializationTypes();
      //  console.log("specializationList", specializationList)
      if (specializationList?.status === 200) {
        setSpecialization(specializationList?.data);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
  };

  const getCountryList = async () => {
    try {
      const res = await GetCountryList();

      //console.log("LIST", res.data.countries);
      setCountries(res.data.countries);
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
  };

  const getTimezoneList = async () => {
    const reqBody = {
      code: formData.selectedCountry,
    };
    try {
      const res = await GetTimezone(reqBody);

      //console.log("LIST", res.data.timezones);
      setTimezoneList(res.data.timezones);
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
  };

  //Form data handling
  const handleFormData = (
    field: string,
    value: string | number | null | boolean
  ) => {
    let countryID: any = null;
    let selectedTimezone: any = null;
    if (field === "selectedCountry") {
      countryID = countries.filter((item: any) => item.code == value)[0].id;
      if (+countryID === 104) {
        selectedTimezone = "Asia/Kolkata";
      }
    }

    if (countryID) {
      setFormData((prevData) => ({
        ...prevData,
        selectedTimezone,
        countryID,
        [field]: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const handleSelectionChange = (value: any) => {
    setSelected(value);
  };

  const handleSpace = (e: any) => {
    if (e.keyCode === 32) {
      setInputISO(inputISO + " ");
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => console.log(codeResponse),
    flow: "auth-code",
  });

  const onGoogleLoginSuccess = async (credentialResponse: any) => {
    try {
      setIsRegistering(true);
      const { credential, clientId, select_by } = credentialResponse;
      const { email, name } = jwtDecode<DecodedToken>(
        credentialResponse?.credential
      );

      if (!credential) {
        //throw new Error("Token decoding failed");
        toast.error("Oops!! Please try again.");
        setIsRegistering(false);
      }

      const googleLoginReqBody = {
        credential,
        clientId,
        select_by,
        //id_token: credential,
      };
      const googleLoginResponse = await SSOAuthLogin(
        googleLoginReqBody,
        "copilot",
        "google"
      );
      const {
        code,
        data: { jwt, role, token },
      } = googleLoginResponse;
      const { user_id } = jwtDecode<any>(jwt);

      if (+code === 200) {
        const setCookieBody: SetCookieInterface = {
          token,
          userID: user_id,
          userDataJwt: jwt,
          utmCampaign,
          utmMedium,
          redirectURL,
          type: "COPILOT",
          regRef: "ccop_expert_reg",
          method: "google",
          event: "uwc_register",
          authUserRole: role,
          provider: email?.split("@")[1],
          displayName: name?.split(" ")[0],
        };

        setCookieOnClientSide(setCookieBody);
      }
    } catch (error) {
      console.error("Error in Google login process:", error);
      toast.error("Error in Google login process.");
      // Handle any errors that occurred during the login process
    }
    //setCredentialCode(credentialResponse?.credential); // Set the credential code received
  };

  const handleVerifyOtp = async () => {
    try {
      const isOTPValid = await VerifyOTP(
        formData?.email?.toLocaleLowerCase(),
        +otp
      );

      if (+isOTPValid?.data?.code == 200) {
        return true;
        // if (isOTPValid?.data?.userExist === false) {
        //   return true;
        // } else {
        //   //toast.warning("Looks like you're already registered! Please log in.");
        //   setErrors((prevData: any) => ({
        //     ...prevData,
        //     otp: "Looks like you're already registered. Please log in.",
        //   }));
        //   setShowAPILoader(false);
        // }
      } else if (
        +isOTPValid?.data?.code > 400 &&
        +isOTPValid?.data?.code < 500
      ) {
        //toast.error(isOTPValid?.data.message);

        setErrors((prevData: any) => ({
          ...prevData,
          otp: isOTPValid?.data?.message,
        }));

        setOtp("");
        setShowAPILoader(false);
        setDisableRegister((prevData: any) => ({
          ...prevData,
          step2: false,
        }));
        if (+isOTPValid?.data?.data?.retry_after) {
          setVerifyOTPCounter(+isOTPValid?.data?.data?.retry_after);
          //  setOpenWarningDialog(true);
        }

        return false;
      } else {
        toast.error("Something went wrong! Please try again.");
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
  };

  /*----------------------------------------------------------
      STEP 1: Check if user exists
      STEP 2: Validate OTP
      STEP 3: Call register function
    -------------------------------------------------------------*/
  const handleStep0 = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setShowAPILoader(true);
    resetErrors();
    ///CHECK IF USER EXISTS

    const userExists = await CheckUserExists(
      formData?.email?.toLocaleLowerCase(),
      "copilot"
    );
    //If user exists, Show message and ask them to try to login instead
    if (userExists) {
      setErrors((prevData) => ({
        ...prevData,
        email: "This email is already registered. Please try logging in!",
      }));
    } else {
      //If user doesn't exist, send to next step
      //setStep(1);
      handleStep1(e);
      setDataLayer({
        redirectURL,
        type: "COPILOT",
        regRef: "ccop_expert_reg_01",
        method: formData?.socialType ? formData?.socialType : "email",
        event: "uwc_register",
        provider: formData?.email?.toLocaleLowerCase()?.split("@")[1],
      });
    }

    setShowAPILoader(false);
  };

  const handleStep1 = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setShowAPILoader(true);
    resetErrors();

    ///Send OTP to email
    //After success go to validate OTP
    try {
      const generateOTPService = await GenerateOTP(
        formData?.email?.toLocaleLowerCase()
      );

      if (+generateOTPService?.data?.code == 200) {
        setStep(2);
      } else if (+generateOTPService?.data?.code === 401) {
        //toast.error(generateOTPService?.data?.message);

        setErrors((prevData: any) => ({
          ...prevData,
          otp: generateOTPService?.data?.message,
        }));
        if (+generateOTPService?.data?.data?.retry_after) {
          setVerifyOTPCounter(+generateOTPService?.data?.data?.retry_after);
          //  setOpenWarningDialog(true);
        }
      } else {
        //Do Something if required
        toast.error("Something went wrong! Please try again.");
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    } finally {
      setShowAPILoader(false);
    }
  };

  const handleStep2 = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (disableRegister?.step2) {
      toast.info("Nice try 👏👏!");
    } else {
      setShowAPILoader(true);
      resetErrors();
      setDisableRegister((prevData: any) => ({
        ...prevData,
        step2: true,
      }));
      const isOTPValidated = await handleVerifyOtp();

      //console.log("isOTPValidated", isOTPValidated);

      //If Validated Register User and Register the user
      if (isOTPValidated) {
        //registerUser();
        setShouldRegister(true);
      }
    }
  };

  const handleResendOTP = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setCounter(30);
    setErrors((prevData: any) => ({
      ...prevData,
      otp: "",
    }));
    toast.success("OTP sent successfully!");

    try {
      const generateOTPService = await GenerateOTP(
        formData?.email?.toLocaleLowerCase()
      );

      if (generateOTPService?.status == 200) {
        toast.success("OTP sent successfully!");
      } else {
        toast.error("Something went wrong! Please try again.");
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    } finally {
      setShowAPILoader(false);
      setDisableRegister((prevData: any) => ({
        ...prevData,
        step2: false,
      }));
    }
  };

  const registerUser = async () => {
    try {
      //Payload for registering User

      const NEWPAYLOAD = {
        name:
          formData?.fullName !== ""
            ? formData?.fullName
            : formData?.email?.toLocaleLowerCase()?.split("@")[0],
        email: formData?.email?.toLocaleLowerCase(),
        phone: formData?.mobileNo,
        country: formData?.selectedCountry,
        country_code: formData?.selectedCountryCode,
        time_zone: formData?.selectedTimezone,
        region: formData?.selectedRegion,
        city: formData?.selectedCity,
        postal_code: formData?.selectedPostalCode,
        specialization: formData?.specialization,
        device_type: getDeviceType(),
        device_id: getDeviceId(),
        social_id: formData?.socialID,
        social_type: formData?.socialType,
      };

      if (NEWPAYLOAD?.name !== "" && NEWPAYLOAD?.email !== "") {
        const isRegistrationComplete = await RegisterCopilotUserJuggad(
          NEWPAYLOAD
        );

        if (+isRegistrationComplete?.data?.code === 200) {
          const TOKEN = isRegistrationComplete?.data?.data?.token;
          const USERID = +isRegistrationComplete?.data?.data?.user_id;
          const USERROLE = isRegistrationComplete?.data?.data?.role;
          const USERJWT = isRegistrationComplete?.data?.data?.jwt;

          const setCookieBody: SetCookieInterface = {
            token: TOKEN,
            userID: USERID,
            userDataJwt: USERJWT,
            utmCampaign,
            utmMedium,
            redirectURL,
            type: "COPILOT",
            regRef: "ccop_expert_reg",
            method: formData?.socialType ? formData?.socialType : "email",
            event: "uwc_register",
            authUserRole: USERROLE,
            provider: formData?.email?.toLocaleLowerCase()?.split("@")[1],
            displayName:
              formData?.fullName !== ""
                ? formData?.fullName
                : formData?.email?.toLocaleLowerCase()?.split("@")[0],
          };

          // console.log("setCookieBody", setCookieBody);

          setCookieOnClientSide(setCookieBody);
        } else {
          toast.error("Something went wrong! Please try again.");
          setDisableRegister((prevData: any) => ({
            ...prevData,
            step2: false,
          }));
          setIsRegistering(false);
        }
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
      setIsRegistering(false);
    } finally {
      setDisableRegister((prevData: any) => ({
        ...prevData,
        step2: false,
      }));
      setShouldRegister(false);

      registeringRef.current = false;

      setFormData({
        email: "",
        fullName: "",
        firstName: "",
        lastName: "",
        title: "`",
        referralCode: "",
        selectedCountry: "",
        selectedCountryName: "",
        selectedTimezone: "",
        selectedRegion: null,
        selectedCity: null,
        selectedPostalCode: null,
        isTermsAccepted: false,
        socialID: null,
        socialType: null,
        phoneCode: "",
        mobileNo: "",
        userType: 4,
        countryID: "",
        userId: "",
        institutionId: "1",
        referenceId: "",
        groupId: "",
        device_type: "",
        device_id: "",
        specialization: "",
      });
    }
  };

  const handleCloseDialog = () => {
    setOpenWarningDialog(false);
  };

  const handleAppleSuccess = (data: any) => {
    // console.log("DATA", data);
    const {
      authorization: { id_token },
    } = data;

    const newDecodedToken = jwtDecode(id_token);
    // console.log("newDecodedToken", newDecodedToken);
  };

  const handleAppleError = (err: any) => {
    console.log("ERR", err);
  };

  const getComputedStyleVariable = (variable: string) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variable)
      .trim();
  };

  const primaryColorValue = getComputedStyleVariable("--primary");
  const rgbaColor = primaryColorValue
    ? `rgba(${parseInt(primaryColorValue.slice(1, 3), 16)}, ${parseInt(
        primaryColorValue.slice(3, 5),
        16
      )}, ${parseInt(primaryColorValue.slice(5, 7), 16)}, 0.48)` // Adjust the alpha value as needed
    : "";

  ///Functions Region End

  return (
    <div className="Register--Form">
      {step === 0 && (
        <div className="grid gap-6">
          <h6 className="mt-1 text-center text-base">
            Sign up with your email address
          </h6>
          <form id="ccop_reg_step1" className="flex flex-col gap-1 mt-5">
            <EmailInput
              value={formData.email}
              handleFormData={handleFormData}
              error={errors.email}
              setErrors={setErrors}
            />
            {/* 
            <UserNameInput
              firstNameVal={formData.firstName}
              lastNameVal={formData.lastName}
              handleFormData={handleFormData}
              error={errors.fullName}
            />

            <CountrySelector
              handleFormData={handleFormData}
              countries={countries}
            />

            {timezoneList && timezoneList?.length > 1 && (
              <TimzoneSelector
                handleFormData={handleFormData}
                timezoneList={timezoneList}
              />
            )} */}

            <TncAgreeSwitch
              handleFormData={handleFormData}
              isTermsAccepted={formData?.isTermsAccepted}
              userCountry={userCountry}
            />

            <button
              style={{
                backgroundColor:
                  disableRegister.step0 || showAPILoader
                    ? rgbaColor
                    : "var(--primary)",
              }}
              className="sendOTP mt-5"
              disabled={disableRegister.step0 || showAPILoader}
              onClick={(e) => handleStep0(e)}
            >
              {showAPILoader && (
                <Loader2 className="animate-spin h-5 w-5 mr-3" />
              )}
              Continue
            </button>
          </form>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t"></span>
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-white px-2 text-slate-400 font-medium">
                Or continue with
              </span>
            </div>
          </div>
          {/* bg-[#202124] hover:bg-[#555658]  rounded-lg h-11 py-0.5*/}
          <div className="grid gap-2">
            <div className="relative pl-2">
              <GoogleLogin
                onSuccess={onGoogleLoginSuccess}
                onError={() => {
                  console.log("Login Failed");
                }}
                //type="icon"
                theme="outline"
                shape="rectangular"
                size="large"
                text="signup_with"
                width={"343"}
                ux_mode="popup"
                logo_alignment="center"
              />
            </div>

            {/*<AppleSignInButton
              className={"h-[40px]"}
              authOptions={{
                clientId: "com.unitedwecare.accounts",
                scope: "name email",
                redirectURI: "https://accounts-stage.unitedwecare.com/",
              }}
              onSuccess={handleAppleSuccess}
              onError={handleAppleError}
              uiType={"dark"}
            />*/}
          </div>

          <div className="text-sm text-center text-slate-900 font-semibold">
            Already have an account? &nbsp;
            <LoginButton redirectURL={redirectURL} type="INLINE" />
          </div>
        </div>
      )}

      {step === 1 && (
        <>
          <h6 className="mt-1 text-center text-base">
            Please give us some more info
          </h6>
          <form id="ccop_reg_step2" className="flex flex-col gap-3 mt-5">
            <PhoneNumberInput
              value={formData.mobileNo}
              phoneCode={formData?.phoneCode}
              countryCode={formData?.selectedCountry}
              handleFormData={handleFormData}
              isValid={validatePhoneNumber(
                formData?.mobileNo,
                formData?.selectedCountry
              )}
            />

            <SpecializationSelector
              handleFormData={handleFormData}
              specializations={specializations}
            />

            <button
              style={{
                backgroundColor:
                  disableRegister.step1 || showAPILoader
                    ? rgbaColor
                    : "var(--primary)",
              }}
              disabled={disableRegister.step1 || showAPILoader}
              className="sendOTP mt-3"
              onClick={(e) => handleStep1(e)}
            >
              {showAPILoader && (
                <Loader2 className="animate-spin h-5 w-5 mr-3" />
              )}
              Continue
            </button>
          </form>
        </>
      )}

      {step === 2 && (
        <>
          <h6 className="mt-1  text-center text-base">
            Please enter One Time Password (OTP) sent to your email.
          </h6>
          <div className="flex flex-col gap-2 mt-5">
            <OtpVerifierInput
              otp={otp}
              setOtp={setOtp}
              error={errors.otp}
              setErrors={setErrors}
              counter={verifyOTPCounter}
              id={"ccop_reg_step3"}
            />

            {verifyOTPCounter === 0 && (
              <div className="mt-5 flex flex-col gap-3">
                {counter > 0 ? (
                  <span className="flex-1 text-sm font-medium text-center text-slate-400 h-9 px-4 py-2">
                    Resend OTP in {counter}s
                  </span>
                ) : (
                  <button
                    className="resendOTP"
                    onClick={(e) => handleResendOTP(e)}
                  >
                    Resend OTP
                  </button>
                )}

                <button
                  style={{
                    backgroundColor:
                      otp.length !== 4 || showAPILoader
                        ? rgbaColor
                        : "var(--primary)",
                  }}
                  onClick={(e) => handleStep2(e)}
                  className="sendOTP"
                  disabled={otp.length !== 4 || showAPILoader}
                >
                  {showAPILoader && (
                    <Loader2 className="animate-spin h-5 w-5 mr-3" />
                  )}
                  Verify OTP
                </button>
              </div>
            )}
          </div>
        </>
      )}

      <div className="relative my-4 hidden">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t"></span>
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-white px-2 text-slate-400 font-medium">Or</span>
        </div>
      </div>

      {/* <GoogleLogin
        onSuccess={onGoogleLoginSuccess}
        onError={() => {
          console.log("Login Failed");
        }}
        //type="icon"
        theme="outline"
        shape="rectangular"
        size="large"
        text="continue_with"
        width={"350"}
        ux_mode="popup"
        logo_alignment="center"
      /> */}
      {isRegistering && (
        <div className="fixed w-full h-full top-0 left-0 bg-black/40 backdrop-blur-sm flex items-center justify-center z-20">
          <Loader2 className="animate-spin h-10 w-10 text-white" />
        </div>
      )}
      <Drawer
        isOpen={openWarningDialog}
        onClose={handleCloseDialog}
        counter={verifyOTPCounter}
      />
    </div>
  );
};

export default ExpertRegisterForm;
